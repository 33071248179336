import { createAction, props } from '@ngrx/store';

import {
  InvestorEntitiesGetResponse,
  InvestorEntitiesPutRequestBody,
  InvestorEntitiesPutResponse,
  InvestorEntityGetResponse,
} from './entities.types';

export const entitiesFetchListStart = createAction('SQUIRREL_ENTITIES/FETCH_LIST_START');
export const entitiesFetchListSuccess = createAction(
  'SQUIRREL_ENTITIES/FETCH_LIST_SUCCESS',
  props<{ payload: InvestorEntitiesGetResponse }>(),
);
export const entitiesFetchListFail = createAction('SQUIRREL_ENTITIES/FETCH_LIST_FAIL');

export const entitiesReset = createAction('SQUIRREL_ENTITIES/RESET');

export const entitiesFetchItemStart = createAction(
  'SQUIRREL_ENTITIES/FETCH_ITEM_START',
  props<{ payload: { investorEntityId: string | number } }>(),
);
export const entitiesFetchItemSuccess = createAction(
  'SQUIRREL_ENTITIES/FETCH_ITEM_SUCCESS',
  props<{ payload: InvestorEntityGetResponse }>(),
);
export const entitiesFetchItemFail = createAction('SQUIRREL_ENTITIES/FETCH_ITEM_FAIL');

export const entitiesUpdateItemStart = createAction(
  'SQUIRREL_ENTITIES/UPDATE_ITEM_START',
  props<{ payload: InvestorEntitiesPutRequestBody }>(),
);
export const entitiesUpdateItemSuccess = createAction(
  'SQUIRREL_ENTITIES/UPDATE_ITEM_SUCCESS',
  props<{ payload: InvestorEntitiesPutResponse }>(),
);
export const entitiesUpdateItemFail = createAction('SQUIRREL_ENTITIES/UPDATE_ITEM_FAIL');

export const entitiesResetItem = createAction('SQUIRREL_ENTITIES/RESET_ITEM');
